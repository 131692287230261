@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gabarito:wght@400;500;600;700;800;900&family=Space+Grotesk:wght@300;400;500;600;700&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  font-family: "Montserrat", sans-serif;
}

.good-font {
  font-family: "Gabarito", sans-serif !important;
}

.scroll-bar-cool-1::-webkit-scrollbar {
  width: 5px;
  background-color: #cccccc00;
}

.scroll-bar-cool-1::-webkit-scrollbar-thumb {
  background-color: #d8ff00;
  border-radius: 12px;
}

.scroll-bar-cool-2::-webkit-scrollbar {
  width: 3px;
  background-color: #cccccc00;
}

.scroll-bar-cool-2::-webkit-scrollbar-thumb {
  background-color: #000;
  border-radius: 12px;
}

.scroll-bar-cool-3::-webkit-scrollbar {
  width: 3px;
  background-color: #cccccc00;
}

.scroll-bar-cool-3::-webkit-scrollbar-thumb {
  background-color: #45464a;
  border-radius: 12px;
}

.scroll-bar-cool-4::-webkit-scrollbar {
  width: 3px;
  background-color: #cccccc00;
}

.scroll-bar-cool-4::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 12px;
}


.scroll-bar-cool-5::-webkit-scrollbar {
  height: 3px;
  background-color: #cccccc00;
}

.scroll-bar-cool-5::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 12px;
}
